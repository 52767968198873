<template>
  <div class="page-global">
    <content-header :title="title" :breadList="['运营中心', '全局触达限制']">
      <template
        #tools
        v-if="
          menuPermission &&
          menuPermission.全局触达限制 &&
          menuPermission.全局触达限制.edit
        "
      >
        <el-button type="primary" round @click="submit" :loading="loading">保存并应用</el-button>
      </template>
    </content-header>
    <el-form :model="formData" ref="ruleForm" label-width="100px">
      <cond-card v-loading="loading" v-for="item in channelsList" :key="item.object" :title="item.name">
        <el-form-item label="是否限制">
          <el-switch v-model="formData[item.object].IsActive" active-value="1" inactive-value="0" :disabled="
            !(menuPermission &&
            menuPermission.全局触达限制 &&
            menuPermission.全局触达限制.edit)
          "> </el-switch>
        </el-form-item>
        <el-form-item label="限制条件" v-if="formData[item.object].IsActive==='1'">
          <cond-item-wrap
            placeholder="所有条件"
            :isEmpty="formData[item.object].length === 0"
          >
            <cond-item
              v-for="(ele, index) in formData[item.object].Limit"
              :key="index"
              v-model="formData[item.object].Limit[index]"
              :index="index"
              :disabled="
                !(menuPermission &&
                menuPermission.全局触达限制 &&
                menuPermission.全局触达限制.edit)
              "
              @remove="detail => handleRemoveCondCarBeha(formData[item.object], index)"
            ></cond-item>
            <div>
              <el-button
                type="text"
                @click="handleAddCondCarBeha(formData[item.object])"
                v-if="
                  menuPermission &&
                  menuPermission.全局触达限制 &&
                  menuPermission.全局触达限制.edit
                "
                >+ 添加条件</el-button
              >
            </div>
          </cond-item-wrap>
        </el-form-item>
      </cond-card>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import condCard from "@/components/cond-card";
import condItemWrap from "@/components/cond-item-wrap";
import util from "@/util";
import condItem from "./components/cond-item";
import { apiGetGlobalLimit, apiUpdateGlobalLimit } from '@/api/channel';
export default {
  components: {
    ContentHeader,
    condCard,
    condItemWrap,
    condItem,
  },
  data() {
    return {
      title: this.$route.meta.title,
      loading: false,
      formData: {
        Sms: {
          IsActive: "0",
          Limit: [],
        },
        Wechat: {
          IsActive: "0",
          Limit: [],
        },
        Wecom: {
          IsActive: "0",
          Limit: [],
        },
        WechatMiniProgram: {
          IsActive: "0",
          Limit: [],
        },
      },
      channelsList: [
        {
          name: "短信",
          object: "Sms",
        },
        {
          name: "微信公众号",
          object: "Wechat",
        },
        {
          name: "企业微信",
          object: "Wecom",
        },
        {
          name: "微信小程序",
          object: "WechatMiniProgram",
        },
      ],
    };
  },
  methods: {
    handleRemoveCondCarBeha(object, index) {
      object.Limit.splice(index, 1);
    },
    handleAddCondCarBeha(object) {
      object.Limit.push({
        TimeValue: 1,
        TimeUnit: '小时',
        LimitMessageNumber: 1,
      });
    },
    submit() {
      this.loading = true
      apiUpdateGlobalLimit(this.formData).then((res) => {
        this.formData = res
        this.$message.success('保存成功！')
      }).finally(() => {
        this.loading = false
      })
    }
  },
  computed: {
    ...mapGetters({
      menuPermission: "common/menuPermission",
    }),
  },
  created() {
    this.loading = true
    apiGetGlobalLimit().then(res => {
      this.formData = res
    }).finally(() => {
      this.loading = false
    })
  },
};
</script>
<style lang="less" scoped>
</style>
