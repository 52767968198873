<template>
  <div class="cond-item-customer">
    <el-row :gutter="20">
      <!-- 序号 -->
      <el-col :span="1" class="index-wrap">
        <div class="index">{{ index + 1 }}</div>
      </el-col>
      <el-col :span="3">
        <span>每个用户</span>
      </el-col>
      <el-col :span="4">
        <el-input-number
          v-model="formData.TimeValue"
          style="width: 100%" :min="1"
          :max="formData.TimeUnit == '小时' ? 24 : formData.TimeUnit == '天' ? 7 : formData.TimeUnit == '周' ? 4 : 3"
          :disabled="disabled"
        ></el-input-number>
      </el-col>
      <el-col :span="4">
        <el-select v-model="formData.TimeUnit" style="width: 100%" :disabled="disabled">
          <!-- <el-option label="分" value="分"></el-option> -->
          <el-option label="小时" value="小时"></el-option>
          <el-option label="天" value="天"></el-option>
          <el-option label="周" value="周"></el-option>
          <el-option label="月" value="月"></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <span>内，最多接收</span>
      </el-col>
      <el-col :span="4">
        <el-input-number v-model="formData.LimitMessageNumber" style="width: 100%" :min="1" :max="1000" :disabled="disabled"></el-input-number>
      </el-col>
      <el-col :span="3">
        <span>条信息推送</span>
      </el-col>
      <el-col :span="1" style="line-height: 36px">
        <i class="el-icon-close" @click="handleRemove" v-if="!disabled"></i>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => {return {}},
    },
    disabled: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      formData: {},
    };
  },
  watch: {
    value: {
      handler(value) {
        if (JSON.parse((JSON.stringify(value))) != JSON.parse((JSON.stringify(this.formData)))) {
          this.formData = value
        }
      },
      deep: true
    },
    formData: {
      handler(value) {
        this.$emit('update:value', value)
      },
      deep: true
    },
    "formData.TimeUnit": {
      handler(value) {
        if (value === '小时') {
          if (this.formData.TimeValue > 24) {
            this.formData.TimeValue = 24
          }
          return
        }
        if (value === '天') {
          if (this.formData.TimeValue > 7) {
            this.formData.TimeValue = 7
          }
          return
        }
        if (value === '周') {
          if (this.formData.TimeValue > 4) {
            this.formData.TimeValue = 4
          }
          return
        }
        if (value === '月') {
          if (this.formData.TimeValue > 3) {
            this.formData.TimeValue = 3
          }
          return
        }
      },
      deep: true
    }
  },
  methods: {
    handleRemove() {
      this.$emit("remove", this.detail);
    },
  },
  mounted() {
    this.formData = this.value
  },
};
</script>

<style lang="less" scoped>
.cond-item-customer {
  margin-bottom: 14px;
  /deep/.el-input-number__decrease, /deep/ .el-input-number__increase {
    line-height: 34px;
    top: 2px;
  }
  .index {
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-top: 7px;
    font-size: 12px;
    background: #f6f7f8;
    border-radius: 50%;
    text-align: center;
  }
  .el-icon-close {
    font-weight: 500;
    color: #9a9a9e;
    cursor: pointer;
  }
  /deep/ .el-date-editor {
    .el-range-separator {
      padding: 0;
    }
  }
}
</style>
